import React from 'react';
import styled from 'styled-components';

import useIsTapped from 'hooks/useIsTapped';
import { mediaSelector } from 'helpers/style-utils';

import { Rounded } from 'components/shared';

import AddButtonIcon from './AddButtonIcon.svg';
import AddButtonSmallIcon from './AddButtonSmallIcon.svg';
import AddButtonMobileIcon from './AddButtonMobileIcon.svg';

interface ContainerProps {
  isActive?: boolean;
  isSmall?: boolean;
  isTapped: boolean;
}

export const Container = styled(({ isActive, isTapped, ...props }: ContainerProps) => (
  <Rounded as="button" {...props} />
))`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ isActive, theme: { colors, currentTheme } }) =>
    isActive ? colors.gullGray : currentTheme.buttonText};
  padding: 8px 10px 8px 31px;
  text-align: left;

  ${p =>
    p.isSmall &&
    `
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding: 7px 10px 6px 28px;
    min-height: 30px;
  `}

  path {
    transition: fill 0.2s ease;
    fill: ${p => p.theme.currentTheme.buttonIcon};
  }

  ${mediaSelector.hoverSupported} {
    &:hover {
      color: ${p => p.theme.currentTheme.text};
      background-color: ${p => p.theme.currentTheme.hover};

      path {
        fill: ${p => p.theme.colors.dodgerBlue};
      }
    }
  }

  ${p =>
    p.isTapped &&
    `
    transition:
      opacity 0s ease,
      background-color 0s ease
    ;
    color: ${p.theme.currentTheme.text};
    background-color: ${p.theme.colors.hawkesBlue};

    && {
      path {
        fill: ${p.theme.colors.dodgerBlue};
      }
    }
  `}

  ${mediaSelector.mobile} {
    font-size: ${p => (p.isSmall ? '1.7rem' : '1.8rem')};
    line-height: 2.1rem;
    padding: ${p => (p.isSmall ? '7px 10px 6px 35px' : '7px 10px 9px 42px')};
    margin: 0;
  }
`;

const StyledIcon = styled(({ isActive, ...props }: { isActive?: boolean }) => (
  <AddButtonIcon {...props} />
))`
  ${mediaSelector.mobile} {
    display: none;
  }
`;

const StyledMobileIcon = styled(({ isActive, ...props }: { isActive?: boolean }) => (
  <AddButtonMobileIcon {...props} />
))`
  display: none;

  ${mediaSelector.mobile} {
    display: block;
  }
`;

const IconContainer = styled.div<{ isActive?: boolean; isSmall?: boolean }>`
  position: absolute;
  left: ${p => (p.isSmall ? '5px' : '10px')};
  top: ${p => (p.isSmall ? '5px' : '12px')};
  display: flex;

  ${mediaSelector.mobile} {
    left: ${p => (p.isSmall ? '8px' : '5px')};
    top: ${p => (p.isSmall ? '7px' : '8px')};
  }

  ${({ isActive, theme: { colors } }) =>
    isActive &&
    `
    path {
      fill: ${colors.gullGray};
    }
  `}
`;

export const AddButtonLabel = styled.span``;

interface Props {
  isActive?: boolean;
  isSmall?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddButton: React.FC<Props> = ({ children, isActive, isSmall, ...rest }) => {
  const { isTapped, tapHandleProps } = useIsTapped();

  return (
    <Container {...{ isActive, isTapped, isSmall }} {...rest} {...tapHandleProps}>
      <IconContainer isActive={isActive} isSmall={isSmall}>
        {isSmall ? (
          <AddButtonSmallIcon />
        ) : (
          <>
            <StyledIcon />
            <StyledMobileIcon />
          </>
        )}
      </IconContainer>
      <AddButtonLabel>{children}</AddButtonLabel>
    </Container>
  );
};

export default AddButton;

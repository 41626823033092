import React from 'react';
import styled from 'styled-components';

import DraggableSheet, {
  Container as DraggableSheetContainer,
} from 'components/shared/DraggableSheet/DraggableSheet';
import { useDropdownContext, Context as DropdownContext } from './DropdownContext';

const Container = styled.div`
  padding: 0 10px;

  ${DraggableSheetContainer} {
    padding: 10px;
  }

  hr {
    margin: 10px 0;
  }
`;

const stopPropagation = (e: React.MouseEvent<Element>) => e.stopPropagation();

// We override parent DropdownContext so we can remove react-laag layer
// after the animation is completed.
const MobileContent: React.FC<{}> = ({ children }) => {
  const { hide, ...context } = useDropdownContext();

  return (
    <Container
      onClick={stopPropagation}
      onContextMenu={stopPropagation}
      onDoubleClick={stopPropagation}
      onMouseDown={stopPropagation}
    >
      <DraggableSheet onClose={hide}>
        {({ onClose }) => (
          <DropdownContext.Provider value={{ ...context, hide: onClose }}>
            {children}
          </DropdownContext.Provider>
        )}
      </DraggableSheet>
    </Container>
  );
};

export default MobileContent;

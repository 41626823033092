import styled, { css } from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

interface ButtonProps {
  primary?: boolean;
  flat?: boolean;
}

const buttonStyles = css`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${p => p.theme.currentTheme.text};
  border: none;
  -webkit-appearance: none;
  outline: none;

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

// prettier-ignore
export const Button = styled.button<ButtonProps>`
  ${buttonStyles}
  background-color: ${p => p.theme.currentTheme.body};
  padding: 0px 36px;
  margin: 0px;
  height: 42px;
  width: 100%;
  border-radius: 6px;
  transition: all 0.1s ease;
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.delimiter};

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.5;
  }

  ${mediaSelector.hoverSupported} {
    &:hover {
      box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.active};
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.active};
  }

  ${({ primary, theme }) => primary && `
    font-weight: 700;
    color: ${theme.colors.white};
    background-color: ${theme.currentTheme.primary};

    && {
      box-shadow: none;
    }

    ${mediaSelector.hoverSupported} {
      &:hover {
        opacity: .85;
      }
    }

    &:focus {
      opacity: .85;
    }
  `}

  ${({ flat }) => flat && `
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 9px 12px 9px 13px;
    text-align: center;
    transform: translateY(-3px);

    ${mediaSelector.hoverSupported} {
      &:hover {
        opacity: .85;
      }
    }

    &:focus {
      opacity: .85;
    }
  `}
`;

interface ControlProps {
  isActive?: boolean;
  primary?: boolean;
  iconColor?: string;
  preferInlineIconStyles?: boolean;
  isInverted?: boolean;
}

export const ControlBase = styled.div<ControlProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px transparent;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.1s ease;
  flex-shrink: 0;

  ${mediaSelector.hoverSupported} {
    &:hover {
      background-color: ${p =>
        p.isInverted ? p.theme.currentTheme.body : p.theme.currentTheme.hover};
    }
  }
`;

export const ControlButton = styled(ControlBase)`
  ${mediaSelector.hoverSupported} {
    ${p =>
      !p.preferInlineIconStyles &&
      `
      &:hover {
        path {
          fill: ${p.theme.currentTheme.primary};
        }
      }
    `}
  }
`;

export const Control = styled(ControlButton)<ControlProps>`
  ${({ isActive, theme }) =>
    isActive &&
    `
    && {
      background-color: ${theme.currentTheme.active};
    }
  `}

  ${({ isActive, primary, preferInlineIconStyles, theme: { currentTheme } }) =>
    !preferInlineIconStyles &&
    `
    && {
      path {
        fill: ${isActive || primary ? currentTheme.primary : currentTheme.buttonIcon};
      }
    }
  `}
`;

export const Rounded = styled.div`
  cursor: pointer;
  min-height: 36px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  transition: all 0.1s ease;

  ${mediaSelector.mobile} {
    min-height: 44px;
  }
`;

export const TransparenControl = styled.button<{ isActive?: boolean }>`
  ${buttonStyles}
  background-color: transparent;
  padding: 0;

  ${p =>
    p.isActive &&
    `
    path {
      fill: ${p.theme.currentTheme.primary}
    }
  `}

  ${mediaSelector.hoverSupported} {
    &:hover {
      path {
        fill: ${p => p.theme.currentTheme.primary};
      }
    }
  }
`;

export default Button;

import { ColorEnum } from '__generated__/globalTypes';

export type Themes = 'light' | 'dark' | 'darkDimmed';

export type Markers = Record<keyof typeof ColorEnum, string>;

type Theme = {
  primary: string;
  red: string;
  body: string;
  text: string;
  link: string;
  notes: string;
  delimiter: string;
  border: string;
  panel: string;
  pwaDelimiter: string;
  disabled: string;
  dropdownContent: string;
  popupContent: string;
  active: string;
  hover: string;
  lightHover: string;
  buttonText: string;
  buttonIcon: string;
  placeholder: string;
  inputBg: string;
  listItemHover: string;
  listItemIconHover: string;
  highlighted: string;
  overlay: string;
  mainSidebarBg: string;
  secondarySidebarBg: string;
  searchInputShadow: string;
  elementPlaceholder: string;
  markers: Markers;
  openCard: {
    body: string;
    inputBg: string;
    headerBg: string;
  };
  comment: {
    body: string;
    ownerCommentBody: string;
  };
  privateBoardIcon: {
    icon: string;
    bg: string;
  };
  boardTrashBg: string;
  toolbar: {
    body: string;
  };
  boardStatusIcon: {
    icon: string;
    bg: string;
  };
};

export type CurrentTheme = {
  currentTheme: Theme;
  themeName: 'light' | 'dark' | 'darkDimmed';
};

export const lightTheme: Theme = {
  primary: '#2387FB',
  red: 'rgb(245,14,42)',
  body: '#fff',
  text: '#000',
  link: '#2387FB',
  notes: '#878787',
  delimiter: 'rgba(0,0,0,.06)',
  border: 'rgba(0,0,0,0.25)',
  panel: '#f7f7f7d6',
  pwaDelimiter: '#F3F5F7',
  disabled: 'rgba(0,0,0,0.25)',
  dropdownContent: '#fff',
  popupContent: '#fff',
  active: '#D2E3FD',
  hover: '#F7F7F7',
  lightHover: '#fff',
  buttonText: 'rgba(172, 173, 174, 0.8)',
  buttonIcon: 'rgba(0, 0, 0, 0.25)',
  placeholder: '#DADADA',
  inputBg: '#fff',
  listItemHover: '#D2E3FD',
  listItemIconHover: '#96A1B5',
  highlighted: 'rgba(254, 233, 48, 0.5)',
  overlay: 'rgba(255,255,255,0.97)',
  mainSidebarBg: 'linear-gradient(0deg,#f9fafa,#f9fafa),linear-gradient(0deg,#fde9e6,#fde9e6)',
  secondarySidebarBg: '#F3F5F7',
  searchInputShadow: '0px 0px 2px rgba(0,0,0,0.25)',
  elementPlaceholder: '#F7F7F7',
  markers: {
    RED: '#FF6A56',
    YELLOW: '#FEE930',
    GREEN: '#9CC447',
    TURQUOISE: '#32DABC',
    BLUE: '#5FC1E9',
    GRAY: '#D8D8D8',
    TRANSPARENT: '',
  },
  openCard: {
    body: '#F3F5F7',
    inputBg: '#FFFFFF',
    headerBg: 'rgba(243, 245, 247, 0.96)',
  },
  comment: {
    body: '#FFFFFF',
    ownerCommentBody: '#d3e4fc',
  },
  privateBoardIcon: {
    icon: 'rgb(187 187 187)',
    bg: '#f9fafa',
  },
  boardTrashBg: 'transparent',
  toolbar: {
    body: '#F3F5F7',
  },
  boardStatusIcon: {
    icon: '#878787',
    bg: '#F7F7F7',
  },
};

const darkTheme: Theme = {
  primary: '#2387FB',
  red: '#FF001F',
  body: '#232A31',
  text: 'rgba(255,255,255,0.9)',
  link: '#2387FB',
  notes: '#878787',
  delimiter: 'rgba(242,242,242,.1)',
  border: 'rgba(255,255,255,0.25)',
  panel: 'rgba(255,255,255,0.1)',
  pwaDelimiter: 'rgba(0,0,0,0)',
  disabled: 'rgba(255,255,255,0.25)',
  dropdownContent: '#000',
  popupContent: 'rgb(40,47,54)',
  active: '#324E84',
  hover: '#333B46',
  lightHover: '#333B46',
  buttonText: 'rgba(255,255,255,0.5)',
  buttonIcon: 'rgba(255,255,255,0.5)',
  placeholder: '#878787',
  inputBg: '#2F3742',
  listItemHover: '#333B46',
  listItemIconHover: 'rgba(255,255,255,0.5)',
  highlighted: 'rgba(254, 233, 48, 0.5)',
  overlay: 'rgba(35,42,49,0.9)',
  mainSidebarBg: '#121315',
  secondarySidebarBg: 'rgb(40,47,54)',
  searchInputShadow: 'none',
  elementPlaceholder: 'rgba(0, 0, 0, 0.2)',
  markers: {
    RED: '#ab4c42',
    YELLOW: '#ab9d35',
    GREEN: '#667f34',
    TURQUOISE: '#2f9482',
    BLUE: '#47849e',
    GRAY: '#939394',
    TRANSPARENT: '',
  },
  openCard: {
    body: 'rgb(40,47,54)',
    inputBg: '#2F3742',
    headerBg: '#21272df2',
  },
  comment: {
    body: '#2F3742',
    ownerCommentBody: '#2e4161',
  },
  privateBoardIcon: {
    icon: 'rgb(137 137 138)',
    bg: '#121315',
  },
  boardTrashBg: '#232a31c4',
  toolbar: {
    body: 'rgb(40,47,54)',
  },
  boardStatusIcon: {
    icon: '#fff',
    bg: '#121315',
  },
};

const darkDimmedTheme: Theme = {
  primary: '#007aff',
  red: '#FF001F',
  body: '#1d1c1d',
  text: '#dfdfdf',
  link: '#007aff',
  notes: '#9b9b9b',
  delimiter: '#3d3d3d',
  border: '#3d3d3d',
  panel: 'rgba(255,255,255,0.1)',
  pwaDelimiter: 'rgba(0,0,0,0)',
  disabled: 'rgba(255,255,255,0.25)',
  dropdownContent: '#141314',
  popupContent: '#222122',
  active: 'rgb(23 100 191)',
  hover: '#323234',
  lightHover: '#323234',
  buttonText: 'rgba(255,255,255,0.5)',
  buttonIcon: 'rgba(255,255,255,0.5)',
  placeholder: '#878787',
  inputBg: '#323232',
  listItemHover: '#53555863',
  listItemIconHover: 'rgba(255,255,255,0.5)',
  highlighted: 'rgba(254, 233, 48, 0.5)',
  overlay: '#1d1c1df5',
  mainSidebarBg: '#282828',
  secondarySidebarBg: '#282828',
  searchInputShadow: 'none',
  elementPlaceholder: 'rgba(0, 0, 0, 0.2)',
  markers: {
    RED: '#ab4c42',
    YELLOW: '#ab9d35',
    GREEN: '#6d863b',
    TURQUOISE: '#2f9482',
    BLUE: '#47849e',
    GRAY: '#939394',
    TRANSPARENT: '',
  },
  openCard: {
    body: '#282828',
    inputBg: '#323232',
    headerBg: 'rgba(33, 33, 33, 0.96)',
  },
  comment: {
    body: '#323232',
    ownerCommentBody: '#1e5596',
  },
  privateBoardIcon: {
    icon: 'rgb(148 148 148)',
    bg: '#282828',
  },
  boardTrashBg: '#1d1c1dcf',
  toolbar: {
    body: '#282828',
  },
  boardStatusIcon: {
    icon: '#fff',
    bg: '#282828',
  },
};

const themes: Record<Themes, Theme> = {
  light: lightTheme,
  dark: darkTheme,
  darkDimmed: darkDimmedTheme,
} as const;

export default themes;

import React from 'react';
import styled from 'styled-components';
import { Rounded } from 'components/shared/Button/Button';

import { mediaSelector } from 'helpers/style-utils';

interface Props {
  isActive?: boolean;
  withActiveIcon?: boolean;
  hoverColor?: string;
  icon?: React.ReactNode;
  mobileIcon?: React.ReactNode;
  children: React.ReactNode | string;
}

type ContainerProps = Pick<Props, Exclude<keyof Props, 'icon' | 'children' | 'withActiveIcon'>> & {
  withIcon: boolean;
};

export type RoundedLinkProps = Pick<Props, Exclude<keyof Props, 'children'>>;

export const Container = styled(Rounded)<ContainerProps>`
  color: ${p => p.theme.currentTheme.text};
  padding: 6px 10px 6px 6px;
  display: flex;
  align-items: center;

  ${mediaSelector.hoverSupported} {
    &:hover {
      background-color: ${p => p.theme.currentTheme.lightHover};
    }
  }

  ${p =>
    p.isActive &&
    `
    && {
      transition:
        opacity 0s ease,
        background-color 0s ease
      ;
      background-color: ${p.theme.currentTheme.active};
    }
  `}

  ${mediaSelector.mobile} {
    padding-top: 5px;
  }
`;

const IconContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  ${mediaSelector.mobile} {
    display: none;
  }

  ${p =>
    p.isActive &&
    `
    path {
      fill: ${p.theme.currentTheme.primary};
    }
  `}
`;

const MobileIconContainer = styled(IconContainer)`
  display: none;

  ${mediaSelector.mobile} {
    display: flex;
    margin-right: 8px;
  }
`;

const RoundedLink: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  icon,
  mobileIcon,
  children,
  isActive = false,
  withActiveIcon = false,
  ...props
}) => (
  <Container {...props} withIcon={!!(mobileIcon || icon)} isActive={isActive}>
    {icon && <IconContainer isActive={withActiveIcon && isActive}>{icon}</IconContainer>}
    {(mobileIcon || icon) && (
      <MobileIconContainer isActive={withActiveIcon && isActive}>
        {mobileIcon || icon}
      </MobileIconContainer>
    )}

    {children}
  </Container>
);

export default RoundedLink;

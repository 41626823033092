import { lightTheme, CurrentTheme, Markers } from './themes';

export const markers: Markers = {
  RED: '#FF6A56',
  YELLOW: '#FEE930',
  GREEN: '#9CC447',
  TURQUOISE: '#32DABC',
  BLUE: '#5FC1E9',
  GRAY: '#D8D8D8',
  TRANSPARENT: '',
};

export const fontStack =
  '-apple-system, "Segoe UI", "Helvetica Neue", Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif';

export const colors = {
  white: '#fff',
  black: '#000',
  red: 'rgb(245, 14, 42)', // red
  dodgerBlue: '#2387FB', // main blue
  gullGray: '#96A1B5', // icons on blue
  hawkesBlue: '#D2E3FD', // mouseover blue
  athensGray: '#F3F5F7', // panel blue
  torchRed: '#FF001F', // red marker
  gray: '#878787', // text gray
  bombay: '#ACADAE', // ?? gray
  silver: '#CCCCCC', // icons gray
  alto: '#DADADA', // text placeholder
  alabaster: '#F7F7F7', // mouseover gray
  dirtyAlabaster: '#F0F0F0', // helper's shortcuts
  monza: '#D0021B', // notifications
  transparentGray: 'rgba(0,0,0,0.25)',
  transparentMidGray: 'rgba(0,0,0,0.4)',
  transparentDarkGray: 'rgba(0,0,0,0.8)',
  transparentThin: 'rgba(0,0,0,0.1)',
  transparentLight: 'rgba(255,255,255,0.3)',
  transparentWhite: 'rgba(255,255,255,0.9)',
  ...markers,
} as const;

export const fonts = {
  titleNormal: `
    font-family: Graphik, ${fontStack};
    font-weight: normal;
  `,
  titleBold: `
    font-family: Graphik, ${fontStack};
    font-weight: 700;
  `,
  titleSemibold: `
    font-family: Graphik, ${fontStack};
    font-weight: 500;
  `,
  default: `
    font-family: HelveticaNeue, ${fontStack};
    font-weight: 400;
  `,
} as const;

const theme = {
  colors,
  fontStack,
  markers,
  fonts,
} as const;

export type Theme = typeof theme & CurrentTheme;

/**
 * Backwards compatibility
 */
const themeWithCurrentTheme: Theme = {
  ...theme,
  currentTheme: lightTheme,
  themeName: 'light',
} as const;

export default themeWithCurrentTheme;

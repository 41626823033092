import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme';
import { mediaSelector } from 'helpers/style-utils';
import { Control } from 'components/shared';

import Icon from './CloseButtonIcon.svg';
import MobileIcon from './CloseButtonMobileIcon.svg';

export const Container = styled(({ color, ...props }) => <Control {...props} />)`
  flex-shrink: 0;

  path {
    fill: ${({ color }: { color: string }) => color};
  }

  ${mediaSelector.hoverSupported} {
    &:hover path {
      fill: ${p => p.theme.colors.dodgerBlue};
    }
  }
`;

const StyledIcon = styled(Icon)`
  display: block;

  ${mediaSelector.mobile} {
    display: none;
  }
`;

const StyledMobileIcon = styled(MobileIcon)`
  display: none;

  ${mediaSelector.mobile} {
    display: block;
  }
`;

interface Props {
  color?: string;
  size?: number;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CloseButton: React.FC<Props> = ({ color = colors.silver, size = 32, onClick }) => (
  <Container
    color={color}
    style={{
      width: `${size}px`,
      height: `${size}px`,
    }}
    onClick={onClick}
  >
    <StyledIcon width={size / 2} height={size / 2} />
    <StyledMobileIcon />
  </Container>
);

export default CloseButton;

import React from 'react';

import { gql } from '@apollo/client';
import styled from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

import { UserpicFragment } from './__generated__/UserpicFragment';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.2s ease;
  border-radius: 50%;
  background-color: ${p => p.theme.currentTheme.body};
`;

// prettier-ignore
const Image = styled.img<{ isHoverable: boolean }>`
  border-radius: 50%;
  ${({ isHoverable, theme }) => isHoverable && `
    ${mediaSelector.hoverSupported} {
      &:hover {
        box-shadow: 0 0 0 2px ${theme.currentTheme.primary};
      }
    }
  `}
`;

// prettier-ignore
const DefaultImage = styled.div<{ isHoverable: boolean }>`
  font-weight: lighter;
  color: ${p => p.theme.currentTheme.notes};
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  transition: font-size 0.2s ease;
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.border};

  ${({ isHoverable, theme }) => isHoverable && `
    ${mediaSelector.hoverSupported} {
      &:hover {
        box-shadow: 0 0 0 2px ${theme.currentTheme.primary};
      }
    }
  `}
`;

// note: in the future we'll remove userpic versioning
type UserpicType = 'small' | 'large';

interface Props {
  type?: UserpicType;
  image?: UserpicFragment | null;
  size?: number;
  title: string | React.ReactNode;
  isHoverable?: boolean;
}

const Userpic: React.FC<Props> = ({ image, type = 'small', size = 44, title, isHoverable }) => {
  const pic = image && image[type];

  return (
    <Container
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
    >
      {pic && pic.url2x ? (
        <Image src={pic.url2x} width={size} height={size} isHoverable={!!isHoverable} />
      ) : (
        <DefaultImage style={{ fontSize: `${size / 2}px` }} isHoverable={!!isHoverable}>
          {typeof title === 'string' ? title.charAt(0) : title}
        </DefaultImage>
      )}
    </Container>
  );
};

export const userpicFragment = gql`
  fragment UserpicFragment on Userpic {
    small {
      ...UserpicImageFragment
    }
    large {
      ...UserpicImageFragment
    }
  }

  fragment UserpicImageFragment on UserpicImage {
    url2x
    width
    height
  }
`;

export default Userpic;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { VALUE_ESCAPE } from 'keycode-js';

import { mediaSelector } from 'helpers/style-utils';

import { MobileVisible } from 'components/shared/Visibility';
import FadeIn from 'components/FadeIn/FadeIn';
import ModalPortal from 'components/Modal/ModalPortal';
import { useQueue } from 'components/shared/WithQueue/WithQueue';
import CloseButton from 'components/shared/CloseButton/CloseButton';

export const MASTER_DETAIL_WIDTH = 380;

const Handler = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 20;

  ${mediaSelector.mobile} {
    left: 0px;
  }
`;

const MobileOverlay = styled(MobileVisible)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 100%;
  background-color: ${p => p.theme.currentTheme.overlay};
`;

const Container = styled.div`
  padding: 10px 10px 10px 0px;
  height: 100%;

  ${mediaSelector.mobile} {
    padding: 46px 0px 0px;
  }
`;

const Wrapper = styled.div`
  width: ${MASTER_DETAIL_WIDTH}px;
  height: 100%;
  position: relative;
  background-color: ${p => p.theme.currentTheme.popupContent};
  border-radius: 8px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};

  ${mediaSelector.mobile} {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    box-shadow: none;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 3;
`;

interface Props {
  onClose?: (e?: KeyboardEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const MasterDetail: React.FC<Props> = ({ onClose = () => {}, children }) => {
  const queue = useQueue();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === VALUE_ESCAPE && queue.isQueuedLast) {
        queue.removeFromQueue();
        onClose(e);
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [queue.isQueuedLast]);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    queue.removeFromQueue();
    onClose(e);
  };

  useEffect(() => {
    queue.pushToQueue();

    return () => {
      onClose();
    };
  }, []);

  return (
    <ModalPortal>
      <Handler>
        {/*
        // @ts-ignore FIXME */}
        <MobileOverlay onClick={onClick} />
        <Container>
          <Wrapper>
            {typeof onClose === 'function' && (
              <CloseButtonContainer>
                {/*
                // @ts-ignore FIXME */}
                <CloseButton onClick={onClick} />
              </CloseButtonContainer>
            )}

            <FadeIn>{typeof children === 'function' ? children() : children}</FadeIn>
          </Wrapper>
        </Container>
      </Handler>
    </ModalPortal>
  );
};

export default MasterDetail;

import React from 'react';
import styled from 'styled-components';

import OnOutsideClick from 'components/OnOutsideClick/OnOutsideClick';
import ModalPortal from './ModalPortal';

type ContainerProps = {
  overlayColor?: string;
  centerContent?: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 16;
  background-color: ${({ overlayColor, theme }) => overlayColor || theme.currentTheme.overlay};
  overflow: scroll;

  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

type Props = {
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: (e: MouseEvent | KeyboardEvent) => void;
} & ContainerProps;

const Modal: React.FC<Props> = ({ isOpen, ...props }) => {
  if (!isOpen) {
    return null;
  }

  const {
    overlayColor,
    centerContent = true,
    children,
    onOpen = () => {},
    onClose = () => {},
  } = props;

  return (
    <ModalPortal onMount={onOpen}>
      <Container
        onMouseDown={e => e.stopPropagation()}
        centerContent={centerContent}
        overlayColor={overlayColor}
      >
        <OnOutsideClick onClick={onClose} style={{ height: 'auto' }} bindToKey={27}>
          {children}
        </OnOutsideClick>
      </Container>
    </ModalPortal>
  );
};

export default Modal;

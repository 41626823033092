import React from 'react';
import { useTranslation } from 'lib/i18n';

import ListItem from 'components/shared/ListItem/ListItem';
import CopyToClipboard from 'components/shared/CopyToClipboard/CopyToClipboard';

import CopyLinkIcon from './CopyLinkIcon.svg';
import CopyLinkMobileIcon from './CopyLinkMobileIcon.svg';

interface Props {
  value: string;
  text?: string;
  successText?: string;
  icon?: React.ReactNode;
  onCopy?: () => void;
}

const CopyLinkListItem: React.FC<Props> = ({ value, text, successText, icon, onCopy }) => {
  const { t } = useTranslation('common');

  return (
    <CopyToClipboard {...{ value, onCopy }}>
      {({ isCopied, handleCopy }) => (
        <ListItem
          icon={icon || <CopyLinkIcon />}
          mobileIcon={icon || <CopyLinkMobileIcon />}
          onClick={() => handleCopy()}
        >
          {isCopied ? successText || t('copied') : text || t('copyLink')}
        </ListItem>
      )}
    </CopyToClipboard>
  );
};

export default CopyLinkListItem;

import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSpring, a, config } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import useResizeObserver from 'use-resize-observer/polyfilled';

const Backdrop = styled(a.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: ${p => p.theme.colors.black};
`;

export const Container = styled(a.div)`
  z-index: 100;
  position: fixed;
  left: 2vw;
  right: 2vw;
  bottom: -100vh;
  height: 100vh;
  border-radius: 12px 12px 0px;
  background: ${p => p.theme.currentTheme.dropdownContent};
  touch-action: none;
  padding: 0px 10px;
`;

const Wrapper = styled.div`
  padding-bottom: 5vh;
`;

type RenderProps = {
  onClose: () => Promise<void>;
};

type Props = {
  onOpen?: () => void;
  onClose: () => void;
  children: React.ReactNode | ((props: RenderProps) => React.ReactNode);
};

const DraggableSheet = ({ onOpen = () => {}, onClose, children }: Props) => {
  const [initHeight, setInitHeight] = useState(0);
  const { ref: heightRef, height = 0 } = useResizeObserver();
  const [{ y }, set] = useSpring(() => ({
    y: 0,
  }));

  const open = useCallback(
    ({ canceled }: { canceled: boolean }) => {
      set({ y: -height, immediate: false, config: canceled ? config.wobbly : config.stiff });
    },
    [height, set],
  );

  const close = async (velocity = 0) => {
    await set({
      y: 0,
      immediate: false,
      config: {
        ...config.stiff,
        velocity,
        clamp: true,
      },
    });
    onClose();
  };

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (!initHeight && height) {
      setInitHeight(height);
    }
  }, [initHeight, height]);

  useEffect(() => {
    if (height) {
      set({ y: -height, immediate: false, config: config.stiff });
    }
  }, [height, set]);

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < -90) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        if (my > height / 2 || vy > 0.5) {
          close(vy);
        } else {
          open({ canceled });
        }
      } else {
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        set({ y: -height + my, immediate: true });
      }
    },
    { filterTaps: true, bounds: { top: 0 }, rubberband: true },
  );

  const bgStyle = {
    opacity: y.to({
      range: [0, initHeight * -1],
      output: [0, 0.4],
    }),
  };

  return (
    <>
      <Backdrop onClick={() => close()} style={bgStyle} />
      <Container {...bind()} style={{ y }}>
        <div ref={heightRef}>
          <Wrapper>
            {typeof children === 'function' ? children({ onClose: close }) : children}
          </Wrapper>
        </div>
      </Container>
    </>
  );
};

export default DraggableSheet;

import styled from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

export const Body = styled.div`
  background-color: ${p => p.theme.currentTheme.dropdownContent};
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray};
  border-radius: 8px;
  padding: 7px 0;

  hr {
    margin-left: 10px;
    margin-right: 10px;
  }

  ${mediaSelector.mobile} {
    box-shadow: none;
    border-radius: 0;
    padding: 0px;
  }
`;

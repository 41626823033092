import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { queryStringToParams } from 'helpers/url';
import { useMobileViewContext } from 'components/boards/MobileViewContext';

interface RenderProps {
  url:
    | string
    | {
        pathname: string;
        query: ParsedUrlQuery;
      };
  as: string;
  isOpened: boolean;
  isMasterDetailPage: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export type Props = {
  id?: string;
  type: 'workspace' | 'user';
  target?: string;
};

type HOCProps = Props & {
  children: (renderProps: RenderProps) => JSX.Element;
};

export const useMasterDetailLink = ({ id, type, target }: Props) => {
  const router = useRouter();
  const { isMobileView } = useMobileViewContext();

  if (isMobileView) {
    return {
      url: `/${type}?${type}Slug=${id}`,
      as: `/${type === 'user' ? 'u/' : ''}${id}`,
      isOpened: true,
      isMasterDetailPage: true,
      onClick: () => {},
    };
  }

  const params = queryStringToParams(router.asPath);
  const [as] = router.asPath.split('?');
  const isOpened = params[type] === id;
  const isMasterDetailPage = router.route === '/user' || router.route === '/workspace';
  const url = isMasterDetailPage
    ? `/${type}?${type}Slug=${id}`
    : {
        pathname: router.pathname,
        query: router.query,
      };

  return {
    url,
    as: isMasterDetailPage ? `/${id}` : `${as}${isOpened ? '' : `?${type}=${id}`}`,
    isOpened,
    isMasterDetailPage,
    onClick: <T extends React.KeyboardEvent | React.MouseEvent>(e: T) => {
      if (target && e.metaKey) {
        e.preventDefault();
        window.open(`${window.location.origin}${target}`, '_blank');
      }
    },
  };
};

const MasterDetailLink: React.FC<HOCProps> = ({ id, type, target, children }) => {
  const renderProps = useMasterDetailLink({ id, type, target });
  return children(renderProps);
};

export default MasterDetailLink;

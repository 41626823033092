import styled from 'styled-components';

interface Props {
  isActive?: boolean;
}

const Counter = styled.div<Props>`
  height: 15px;
  font-size: 1rem;
  line-height: 1.2rem;
  display: inline-block;
  border-radius: 8px;
  padding: 0px 3px;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.gullGray : theme.colors.silver)};
  font-weight: 600;
  border: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.gullGray : theme.colors.silver)};
`;

export default Counter;

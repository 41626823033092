import { createGlobalStyle } from 'styled-components';
import normalize from 'styled-normalize';

import { fontStack, fonts, Theme } from 'styles/theme';
import swiperGlobalStyles from 'styles/swiper';
import { mediaSelector } from 'helpers/style-utils';

export default createGlobalStyle<{ theme: Theme }>`
  ${normalize}
  ${swiperGlobalStyles}

  body,
  html,
  body > #__next {
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.currentTheme.body};

    ${mediaSelector.mobile} {
      width: 100%;
      overflow-x: hidden;
      user-select: none;
    }
  }

  html {
    font-family: ${fontStack};
    font-size: 62.5%;
    color: ${p => p.theme.currentTheme.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }

  body {
    font-size: 1.6rem;
    line-height: 1.9rem;
    overscroll-behavior-x: none;
  }

  *,
  ::before,
  ::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
  }

  body.is-dragging {
    user-select: none;
    pointer-events: none;
    cursor: -webkit-grabbing;
  }

  // TODO: move everything below to styled-components
  a {
    color: ${p => p.theme.currentTheme.text};
    text-decoration: none;
  }

  .--link {
    color: ${p => p.theme.currentTheme.link};
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }

    &.--reversed {
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  button {
    border: none;
    -webkit-appearance: none;
    outline: none;
    background: none;

    &:not([disabled]) {
      cursor: pointer;
    }
  }

  h1 {
    ${fonts.titleBold}
    color: ${p => p.theme.currentTheme.text};
    font-size: 3.8rem;
    line-height: 4.6rem;
    margin: 0;
    word-break: break-word;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: 0;
    word-break: break-word;
  }

  hr {
    height: 1px;
    border: none;
    margin: 7px 0;
    background-color: ${p => p.theme.currentTheme.delimiter};
  }

  #layers {
    z-index: 1000;
  }
`;

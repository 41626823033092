import React from 'react';
import styled from 'styled-components';
import { Control } from 'components/shared/Button/Button';
import Icon from './TriggerIcon.svg';

export type Props = {
  width?: string;
  height?: string;
  isActive?: boolean;
  isInversed?: boolean;
};

export const Container = styled(Control)<Props>`
  width: ${p => p.width};
  height: ${p => p.height};

  ${p =>
    p.isInversed &&
    `
    background-color: ${p.isActive ? p.theme.currentTheme.body : 'transparent'};

    path {
      fill: ${p.isActive ? p.theme.currentTheme.primary : p.theme.currentTheme.buttonIcon};
    }
    
    &:hover {
      background-color: ${p.theme.currentTheme.body};  
    }
  `}
`;

// &:hover {
//   background-color: ${p.theme.currentTheme.body};

//   path {
//     fill: ${p.theme.currentTheme.primary};
//   }
// }

const Trigger = React.forwardRef<HTMLDivElement, Props>(
  ({ width = '24px', height = '24px', ...props }, ref) => (
    <Container ref={ref} {...props} {...{ width, height }}>
      <Icon />
    </Container>
  ),
);

export default Trigger;

import React, { Component } from 'react';

import styled from 'styled-components';

// prettier-ignore
export const Container = styled.div<{ duration: number | string; mounted: boolean }>`
  height: 100%;
  transition: opacity .${props => props.duration}s ease-in;
  opacity: ${props => (props.mounted ? '1' : '0')};
`;

interface Props {
  duration?: number | string;
  delay?: number;
  style?: object; // TODO: remove style
}

interface State {
  mounted: boolean;
}

class FadeIn extends Component<Props, State> {
  state = {
    mounted: false,
  };

  componentDidMount() {
    const { delay = 0 } = this.props;
    setTimeout(
      () =>
        this.setState({
          mounted: true,
        }),
      delay,
    );
  }

  render() {
    const { duration = 150, style, children } = this.props;
    const { mounted } = this.state;
    return (
      <Container mounted={mounted} duration={duration} style={style}>
        {children}
      </Container>
    );
  }
}

export default FadeIn;

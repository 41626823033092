import styled, { css } from 'styled-components';

export const inputStyles = css`
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: -0.5px;
  border: none;
  width: 100%;
  padding: 0px 10px;
  height: 44px;
  border-radius: 6px;
  color: ${p => p.theme.currentTheme.text};
  background-color: ${p => p.theme.currentTheme.inputBg};
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.placeholder};
  caret-color: ${p => p.theme.currentTheme.primary};
  transition: box-shadow 0.2s ease;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.active};
  }

  ::-webkit-input-placeholder {
    color: ${p => p.theme.currentTheme.placeholder};
  }

  ::-moz-placeholder {
    color: ${p => p.theme.currentTheme.placeholder};
  }

  :-ms-input-placeholder {
    color: ${p => p.theme.currentTheme.placeholder};
  }

  :-moz-placeholder {
    color: ${p => p.theme.currentTheme.placeholder};
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

export const InputError = styled.div.attrs({
  role: 'alert',
})`
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${p => p.theme.colors.torchRed};
  margin: 5px 0 10px;
`;

export default Input;
